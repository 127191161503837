import * as React from 'react';
import { Typography, Box, Backdrop, Modal, Fade, IconButton, Button, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

const style = (isMobile: boolean) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : '80%',
  maxWidth: isMobile ? '100%' : 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '8px',
  outline: 'none', // Remove the border
  maxHeight: '80vh',
  overflowY: 'auto',
});

interface Data {
  id: number;
  title: string;
  url: string;
  imgUrl: string;
  description1: string;
  description2: string;
  description3: string;
}

interface ModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  selectedItem: Data;
}

const TransitionsModal: React.FC<ModalProps> = ({ isModalOpen, closeModal, selectedItem }) => {
  const isMobile = useMediaQuery('(max-width:900px)'); // Adjust the breakpoint

  const commonTypographyStyles = {
    mt: 1,
    fontWeight: '100', // Thin font
    fontSize: '0.8rem', // Equivalent to text-xl in Tailwind
    fontFamily: 'Arial, Helvetica, sans-serif',
    wordWrap: 'break-word',
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 1800,
        },
      }}
    >
      <Fade in={isModalOpen}>
        <Box sx={style(isMobile)}>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: isMobile?'4px':'12px',
              top: isMobile?'15px':'12px',
            
              color: (theme) => theme.palette.grey[800],
            }}
          >
            <CloseIcon sx={{width:'30px', height:'30px'}} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: isMobile? 'end':'none',
              alignItems: isMobile ? 'none':'center'
            }}
          >
                      {isMobile &&
              <Typography id="transition-modal-title" variant="h4" component="h2" sx={{ fontWeight: '700', fontSize: '1.4rem' }}>
                {selectedItem.title}
              </Typography>
}
            <Box
              component="img"
              src={selectedItem.imgUrl}
              alt={selectedItem.title}
              sx={{
                width: isMobile ? '100%' : '300px',
                height: isMobile ? '200px' : '300px',
                objectFit:'cover',            
                borderRadius: '8px',
                mt:isMobile? 2:0,
                mr: isMobile ? 0 : 2,
                mb: isMobile ? 2 : 0,
              }}
            />
            <Box display='flex' flexDirection='column' p={isMobile?0:1} justifyContent={!isMobile?'space-between':'none'} height={isMobile?'25vh':'44vh'} sx={{overflowY:isMobile?'auto':'hidden'}}>
              <Box>
                              {!isMobile &&
              <Typography id="transition-modal-title" variant="h4" component="h2" sx={{ fontWeight: '700', fontSize: '1.4rem' }}>
                {selectedItem.title}
              </Typography>
}
              <Box height='28vh' sx={{ overflowY: 'auto', width: isMobile? 'auto':'100%', maxWidth: isMobile?'100%':'auto',textAlign:isMobile?'left':'none'}}>
                <Typography id="transition-modal-description" sx={commonTypographyStyles}>
                  {selectedItem.description1}
                </Typography>
                <Typography id="transition-modal-description" sx={commonTypographyStyles}>
                  {selectedItem.description2}
                </Typography>
                <Typography id="transition-modal-description" sx={commonTypographyStyles}>
                  {selectedItem.description3}
                </Typography>
              </Box>
              </Box>

            {!isMobile &&
             <a href={selectedItem.url} target="_blank" rel="noopener noreferrer">
             <Button variant="contained" endIcon={<SendIcon />}
               sx={{               
                 backgroundColor: 'black',
                 borderRadius: '10px',
                 '&:hover': {
                   backgroundColor: 'white',
                   color: 'black', // Change this to the desired hover color
                 },
               }}
             > Play Now
             </Button>
             </a>
            }
            </Box>

{isMobile &&
            <a href={selectedItem.url} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" endIcon={<SendIcon />}
                sx={{
                  mt: 2,
                  backgroundColor: 'black',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'black', // Change this to the desired hover color
                  },
                }}
              > Play Now
              </Button>
              </a>
}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TransitionsModal;
