import React, { useState } from 'react';
import {Box, useMediaQuery } from '@mui/material';
import './home.css'; // Ensure this path is correct for your project
import TransitionsModal from './Modal'; // Ensure the correct path
import NavBar from './NavBar';
import Card from './Card';
import Data from './Data';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isMobile = useMediaQuery('(max-width:900px)');

  const openModal = (item :any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div 
    //  style={{ background: "linear-gradient(to top right, #93A5CF, #E4EfE9)" }}
     >
      <NavBar />
      <Box className="max-w-[1380px] p-4 h-full mx-auto"
       sx={{padding: isMobile?'2.25rem':'8rem', paddingTop:'1.5rem'}}
       >
        <Card data={Data} openModal={openModal} />
        {isModalOpen && selectedItem && (
          <TransitionsModal isModalOpen={isModalOpen} closeModal={closeModal} selectedItem={selectedItem} />
        )}
      </Box>
    </div>
  );
};

export default Home;
