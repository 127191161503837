import React from 'react';
import logo from './assets/sciever_logo.png'
const NavBar = () => {
  return (
    <nav className="pt-4 bg-[#EAF0FF]">
    <div className="max-w-[1380px] mx-auto">
      <div className="flex gap-3 items-center justify-center">
        {/* Logo */}
        <div className="flex">
          <img src={logo} alt="Logo" width="45px" height="45px" />
        </div>
        <h1 className="text-xl  font-bold">Welcome to Sciever Inc. Games</h1>
      </div>
    </div>
  </nav>
  )
}

export default NavBar