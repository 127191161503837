import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

interface Data {
  id: number;
  title: string;
  url: string;
  imgUrl: string;
  description1: string;
  description2: string;
  description3: string;
}

interface CardProps {
  data: Data[];
  openModal: (item: Data) => void;
}

const Card: React.FC<CardProps> = ({ data, openModal }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {data.map((item) => (
        <div key={item.id} className="bg-gradient-to-b from-black via-transparent to-black 
        rounded-md overflow-hidden shadow-sm transition-transform transform hover:scale-105 duration-300">
          <div className="relative w-full max-w-md">
            <a href={item.url}  rel="noopener noreferrer">
              <img src={item.imgUrl} className="h-64 w-full object-cover" alt={item.title} />
            </a>
            <div className="absolute bottom-0 left-0 w-full h-14 bg-gradient-to-t from-black to-transparent"></div>
            <h1 className="absolute bottom-3 font-bold left-3 text-white text-left z-10">{item.title}</h1>
            <InfoIcon
              onClick={() => openModal(item)}
              className="absolute bottom-3 right-3 z-20 text-white text-sm cursor-pointer"
              sx={{width:'20px', height:'20px'}}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
