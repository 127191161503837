// App.js
import React from 'react';
import 'tailwindcss/tailwind.css';
import Home from './Home';
import './index.css';

function App() {
  return (
    <Home />
  );
}

export default App;
